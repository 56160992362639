import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function DigitalSignatureCertificate() {
  /* Slider */
  // const DscSlider = [
  //   {
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Digital Signature Certificate Registration`,
  //     content: `Apply for (DSC)Digital Signature Certificate with TODAYFILINGS Experts`,
  //     image: "/imgs/registration/dsc/dsc-sliders.png",
  //     alt_tag: "Best Online Digital Signature Certificate Service in Hosur",
  //   },
  //   {
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Digital Signature Certificate Registration`,
  //     content: `Best Digital Signature Certificate (DSC) Registration Service`,
  //     image: "/imgs/registration/dsc/dsc-slide.png",
  //     alt_tag: "Best Online Digital Signature Certificate Near Bangalore",
  //   },

  //   {
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Digital Signature Certificate Registration Service in Hosur`,
  //     content: `Well Popular  Digital Signature Certificate Service in Hosur`,
  //     image: "/imgs/registration/dsc/dsc.png",
  //     alt_tag: "Best Online Digital Signature Certificate in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Digital Signature Certificate`,
    buyBtnLink: `#pricing-buy`,
    price: `1999`,
    f_price: `3499`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };

  /* About Data With Form*/
  const DigitalAboutData = {
    header: `Digital Signature Certificate (DSC)`,
    sub_title: `FOR PERSONAL AND BUSINESS (DSC) ONLINE REGISTRATION.`,
    content_paragraph: [
      ` Digital signature certificates in India heavily rely on "public key encryptions" to produce DSC or the signatures. A DSC, or digital
         signature certificate, contains details about the user, including name, email address, nationality, pin code, certificate issue
          date, and name of Certifying Authority.`,

      // `In India, a digital signature certificate is basically a mathematical formula that helps to authenticate a signature and preserve data
      // up until it reaches its destination. The short answer to the question of how vital it is to obtain a digital signature certificate in India
      //  is that it is very important.`,
    ],
  };

  /* Scroll Nav Data */
  const DigitalScrollId = [
    {
      id: `#dsc`,
      heading: `DSC`,
    },
    {
      id: `#apply`,
      heading: `Steps to Apply`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const DigitalIcData = {
    id: 'dsc',
    mt_div: 'mt-5',
    mt_img: 'mt-1',
    header: 'Significance of DSC',
    image: '/imgs/registration/dsc/dsc-significant.png',
    alt_tag: 'Digital signature certificates ',
    points: [
      {
        head: '',
        content: `Since DSC operates electronically, the chances of a
        certificate being copied are extremely slim. The certificate
        cannot be changed, tampered with, or copied, unlike physical
        signatures. A strong password is provided to the owner, who
        must use it to access his signature. The papers are
        protected and verified by DSC.`,
        icon: true,
      },
      {
        head: '',
        content: `Several of the components that characterize the importance of DSC include the following:`,
        icon: true,
      },
      {
        head: '',
        content: `Ensuring the Safety`,
        icon: true,
      },
      {
        head: '',
        content: `Legal Validity`,
        icon: true,
      },
      {
        head: '',
        content: `Lower Time`,
        icon: true,
      },
      {
        head: '',
        content: `Easier Procedures`,
        icon: true,
      },
      {
        head: '',
        content: `Improving Business Operations`,
        icon: true,
      },
      {
        head: '',
        content: `Helps to Save Cost`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const DigitalCfData = {
    id: 'apply',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Steps For Applying DSC In India',
    content: `The procedures must be followed in order to apply for a DSC, or digital signature certificate, in India are as follows:`,
    body: [
      {
        head: '',
        points: `Access the e-Mudhra Web Portal`,
        icon: true,
      },
      {
        head: '',
        points: `Enter the Information as Required by the Form`,
        icon: true,
      },
      {
        head: '',
        points: `OTP Validation`,
        icon: true,
      },
      {
        head: '',
        points: `Verify and Proceed`,
        icon: true,
      },
      {
        head: '',
        points: `In order to complete the OTP verification, click verify now.`,
        icon: true,
      },
      {
        head: '',
        points: `Verification of Information on Certificate`,
        icon: true,
      },
      {
        head: '',
        points: `To e-sign, you need a PIN, a user ID, and an OTP.`,
        icon: true,
      },
      {
        head: '',
        points: `For the application, a special identifier must be developed.`,
        icon: true,
      },
      {
        head: '',
        points: `The Application ID should be written down for future use.`,
        icon: true,
      },
      {
        head: '',
        points: `Video Recording`,
        icon: true,
      },
      {
        head: '',
        points: `Query to be Addressed`,
        icon: true,
      },
      {
        head: '',
        points: `The application will be finished once you have Fill out the all details and answered all of the questions and reviewed it.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const DSCImages = [
    '/imgs/registration/dsc/dsc-imgslider.png',
    '/imgs/registration/dsc/dsc-img-slider.png',
    // "/imgs/registration/dsc/dsc-img-sliders.jpg",
  ];

  /* SliderContent Component Data */
  const DigitalSCData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: '',
    mt_img: 'mt-5',
    header: 'Benefits Of A Digital Signature Certificate',
    content: [
      {
        points: `Helps in Making Easier and Fast Payment`,
        icon: true,
      },

      {
        points: `Saves Cost and Time,Reduces Fraud`,
        icon: true,
      },
      {
        points: `Increased Authenticity,Enhanced Security`,
        icon: true,
      },
      {
        points: `Validity for the Future`,
        icon: true,
      },
      {
        points: `Enhanced Customer Service`,
        icon: true,
      },
      {
        points: `Increases validity of Documents`,
        icon: true,
      },
      {
        points: `Environment Friendly Decision`,
        icon: true,
      },
      {
        points: `Increases Efficiency of Business`,
        icon: true,
      },
    ],
    images: DSCImages,
  };

  /* Img Content Component Data */
  const DigitalsIcData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-1',
    header:
      'Documents Required For Digital Signature Certificate Or DSC In India',
    image: '/imgs/registration/dsc/dsc-doc.png',
    alt_tag: 'professional tax registration',
    points: [
      {
        head: '',
        content: `ID Proof for Photo`,
        icon: true,
      },
      {
        head: '',
        content: `Proof for Address`,
        icon: true,
      },
      {
        head: '',
        content: `An application form for DSC duly filled in by the Applicant.`,
        icon: true,
      },
      {
        head: 'Why TODAYFILINGS?',
        content: `We assist you in meeting your needs in an easy way. With the help of our specialists, servicing you is a simple task for us, so we can guarantee a hassle-free service.`,
        icon: true,
      },
      {
        head: '',
        content: ` TODAYFILINGS serves as a one-stop shop for all of your business, financial, and compliance needs. Simply ping us and let us know what's on your mind; we have the answer.`,
        icon: true,
      },
      {
        head: '',
        content: `We strive to find solutions, not just fix the issue!`,
        icon: true,
      },
    ],
  };

  var DigitalSignatureFAQ = [
    {
      header: 'What is a Digital Signature Certificate?',
      body: [
        {
          content: `Digital Signature Certificates (DSC) are the digital equivalent (that is electronic format) of
          physical or paper certificates. Few Examples of physical certificates are drivers' licenses, passports or
          membership cards. Certificates serve as proof of identity of an individual for a certain purpose; for example,
           a driver's license identifies someone who can legally drive in a particular country. Likewise, a digital
           certificate can be presented electronically to prove one’s identity, to access information or services on
           the Internet or to sign certain documents digitally.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Why is Digital Signature Certificate (DSC) required?',
      body: [
        {
          content: `Physical documents are signed manually, similarly, electronic documents, for example e-forms are required
          to be signed digitally using a Digital Signature Certificate.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who issues the Digital Signature Certificate?',
      body: [
        {
          content: `A licensed Certifying Authority (CA) issues the digital signature. Certifying Authority (CA) means
          a person who has been granted a license to issue a digital signature certificate under Section 24 of the
          Indian IT-Act 2000.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Which Class of DSC is required for MCA Portal E-filing?',
      body: [
        {
          content: `The person or organization should get a Class 2 or Class 3 DSC that
          has been legitimately granted by a Certifying Authority or CA with a
          valid license before fulfilling any MCA e-filing requirements.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Which classes of DSC are considered valid for the MCA Program?',
      body: [
        {
          content: `DSC of Class 2 and Class 3 category issued by a
          licensed Certifying Authority (CA) needs to be obtained for
           e-filing on the MCA Portal.`,
          icon: false,
        },
      ],
    },
    {
      header: `Do I need to have DIN or Director Identification Number to apply for
      DSC?`,
      body: [
        {
          content: ` No. There is no need stated in any law that states only those
          possessing DIN are eligible to apply for DSC.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'How many days will it take to issue DSC?',
      body: [
        {
          content: `A DSC could be issued in as little as three days or as much as
          seven.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'Is Digital Signature Certificate holding any legal status?',
      body: [
        {
          content: ` Yes, according to the terms of the applicable law, DSC has legal
          standing and is admissible in the national court of law.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How much time do CAs take to issue a DSC?',
      body: [
        {
          content: `The time generally taken by CAs to issue a DSC may vary from three to seven working days.
          Also on fulfilment of terms and conditions DSC is issued by some CA’s on same day based on eKYC for
          Aadhaar Holders.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the validity period of a Digital Signature Certificate?',
      body: [
        {
          content: `The Certifying Authorities are authorized to issue a Digital Signature Certificate with a validity of one or two years.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Digital Signature Certificate'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={DscSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={DigitalAboutData} />

          <Counter />
          <ScrollNav scroll_data={DigitalScrollId} />
          <ImgContent item={DigitalIcData} />

          <ContentForm CFSection_data={DigitalCfData} />

          <ImgContent item={DigitalsIcData} />

          <SliderContent ScSection_data={DigitalSCData} />

          <Cta />
          <Guidance />

          <FAQAccord items={DigitalSignatureFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
